<template>
  <div class="znzz">
    <div class="header">
    </div>
    <iframe class="iframe" src="http://27.128.231.193:8000/snms/ui/login"></iframe>
  </div>
</template>

<script>



export default {
  name: "",
  components:{
  },
  data(){
    return  {

    }
  },
  created() {
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
  overflow-x:hidden;
}
.header{
  height: 68px;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background:rgba(0,111,240,0.5)!important;
  }
}
.iframe{
  width: 100%;
  min-height: calc(100vh - 70px);
  border: none;
  overflow-x:hidden;
}
</style>